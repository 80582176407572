import { ApolloError } from '@apollo/client';
import { ServerError } from 'apollo-link-http-common';
import {
  ERROR_MESSAGE_CLASS_TO_DO_TOKEN,
  ERROR_MESSAGE_FAILED_AUTHENTICATION,
  ERROR_MESSAGE_ALREADY_LOGGED_IN,
  ERROR_MESSAGE_CLASS_NOT_FOUND,
  ERROR_MESSAGE_SESSION_IS_STALE,
} from 'global/constants';
import { getServerErrorMessage } from 'lib/apollo.utils';

export function isFailedToFetch(error?: ApolloError) {
  return error?.networkError?.message.includes('Failed to fetch');
}

export function isAlreadyLoggedIn(error?: ApolloError) {
  return (error?.networkError as ServerError)?.result?.errors?.[0]?.message.includes(
    ERROR_MESSAGE_ALREADY_LOGGED_IN
  );
}

export function isNotFound(error?: ApolloError) {
  return (
    error &&
    (error.message.includes('Not Found') ||
      error.message.includes('not-found') ||
      (error?.networkError as ServerError)?.statusCode === 404)
  );
}

export function isUnauthorised(error?: ApolloError) {
  return error?.networkError?.message.includes('unauthorised');
}

export function isFailedClassToDoAuth(error?: ApolloError) {
  const errorMessage = getServerErrorMessage(error) || (error && error.message);
  return (
    !!errorMessage && errorMessage.includes(ERROR_MESSAGE_FAILED_AUTHENTICATION)
  );
}

export function getDebugInfo() {
  const navigator = window.navigator as any;
  const connection = navigator && navigator.connection;
  const info = [
    Date(),
    window.navigator.userAgent,
    navigator.onLine ? 'isOnline' : 'isOffline',
  ];

  if (connection) {
    if (connection.downlink) info.push(connection.downlink);
    if (connection.type) info.push(connection.type);
  }
  return info.join(', ');
}

export function isExpiredClassToDoTokenServerError(error?: ServerError) {
  return error?.result?.message === ERROR_MESSAGE_CLASS_TO_DO_TOKEN;
}

export function isNoClassError(error?: ServerError) {
  return error?.result?.message === ERROR_MESSAGE_CLASS_NOT_FOUND;
}

export function isStaleSessionServerError(error?: ServerError) {
  return error?.result?.message === ERROR_MESSAGE_SESSION_IS_STALE;
}
