import styled, { css } from 'styled-components';

import {
  rem,
  mediaUp,
  narrowContainerWidth,
  centeredContainerWidth,
} from 'approot/shared/style';
import { grey1 } from 'approot/shared/styles/colours.style';
import {
  spacing16,
  spacing24,
  spacing32,
  spacing40,
  spacing48,
} from 'approot/shared/styles/spacing.style';
import { PrintHidden } from 'approot/shared/styles/print.style';
import { NavigationClassesToggle } from './navigation/classes/navigation-classes.style';
import { Dropdown } from '../dropdown/dropdown.style';
import { flexCentreAlignCss } from '../styles/flex.style';
import { BackgroundImageUrls } from 'approot/shared/layout/layout.constants';

export const LAYOUT_TRANSITION_DURATION = 300;
export const LAYOUT_HEADER_HEIGHT = 64;
export const LAYOUT_CONTENTS_MAX_WIDTH = 1600;
export const LAYOUT_FOOTER_TOP_SPACING = spacing40;
export const LAYOUT_SIDE_PADDING = spacing48;
export const LAYOUT_DRAWER_MAX_WIDTH = 768;
export const LAYOUT_DRAWER_MAX_WIDTH_SMALL = 376;
export const LAYOUT_LARGE_NAVIGATION_DRAWER = 264;
export const LAYOUT_MEDIUM_NAVIGATION_DRAWER = 90;
export const LAYOUT_LARGE_NAVIGATION_BREAKPOINT = '(min-width: 1370px)';

export const baseLayoutBounds = css`
  // safe-area-inset to pad content on devices where viewport fit is enabled
  padding-left: ${rem(spacing16)};
  padding-right: ${rem(spacing16)};
  padding-left: calc(env(safe-area-inset-left) + ${rem(spacing16)});
  padding-right: calc(env(safe-area-inset-right) + ${rem(spacing16)});

  ${mediaUp.xl`
    padding-left: ${rem(LAYOUT_SIDE_PADDING)};
    padding-right: ${rem(LAYOUT_SIDE_PADDING)};
  `}
`;

type ContentWrapperProps = {
  $offset?: number;
  isFullPageHeight?: boolean;
};
export const ContentWrapperStyled = styled.div<ContentWrapperProps>`
  background-color: ${grey1};
  min-height: calc(100vh - ${LAYOUT_HEADER_HEIGHT}px);

  // When true this will prevent displaying the default browser scrollbar on pages that dont need it
  ${({ isFullPageHeight }) =>
    !isFullPageHeight &&
    `
    margin-bottom: 2px;
  `}

  ${({ $offset }) =>
    $offset &&
    `
    margin-left: ${rem($offset)};
    margin-left: calc(env(safe-area-inset-left) + ${rem($offset)});
  `}
`;

type MainLayoutProps = {
  isFullPageHeight?: boolean;
  withFooter?: boolean;
  contentBackgroundColour?: string;
};

export const MainStyled = styled.main<MainLayoutProps>`
  position: relative;

  ${({ contentBackgroundColour }) =>
    contentBackgroundColour &&
    `
    background-color: ${contentBackgroundColour};
  `}

  ${({ withFooter }) => `
    min-height: ${withFooter ? `calc(100vh - 360px)` : '100vh'};
  `}

  ${({ isFullPageHeight }) =>
    isFullPageHeight &&
    `
    min-height: calc(100vh - ${LAYOUT_HEADER_HEIGHT}px);
  `}
`;

type SimpleLayoutWrapperProps = {
  contentBackgroundColour?: string;
  fullPageBackgroundImage?: BackgroundImageUrls;
};
export const SimpleLayoutWrapperStyled = styled(ContentWrapperStyled)<
  SimpleLayoutWrapperProps
>`
  ${({ contentBackgroundColour }) =>
    contentBackgroundColour &&
    `
    background-color: ${contentBackgroundColour};
  `}

  ${({ fullPageBackgroundImage }) =>
    fullPageBackgroundImage &&
    css`
      background-image: url(${fullPageBackgroundImage.base});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      ${mediaUp.lg`
        background-image: url(${fullPageBackgroundImage.l});
      `}

      ${mediaUp.xl`
        background-image: url(${fullPageBackgroundImage.xl});
      `}

      @media (min-width: 1401px) {
        background-image: url(${fullPageBackgroundImage.xxl});
      }

      @media (min-width: 2001px) {
        background-image: url(${fullPageBackgroundImage.xxxl});
      }
    `}
`;

type SimpleLayoutMainProps = {
  contentBackgroundColour?: string;
};

export const SimpleLayoutMainStyled = styled.main<SimpleLayoutMainProps>`
  position: relative;

  ${({ contentBackgroundColour }) =>
    contentBackgroundColour &&
    `
    background-color: ${contentBackgroundColour};
  `}
`;

type LayoutContentProps = {
  withMaxWidth?: boolean;
  hiddenPrint?: boolean;
};

export const LayoutContentStyled = styled.div<LayoutContentProps>`
  ${baseLayoutBounds};

  padding-top: ${rem(spacing32)};
  padding-bottom: ${rem(spacing32)};

  ${mediaUp.lg`
    padding-top: ${rem(spacing48)};
    padding-bottom: ${rem(spacing48)};
  `};

  ${({ withMaxWidth }) =>
    withMaxWidth &&
    `
    margin: auto;
    max-width: ${rem(LAYOUT_CONTENTS_MAX_WIDTH + LAYOUT_SIDE_PADDING * 2)};
  `};

  @media print {
    padding: 0;
  }

  ${({ hiddenPrint }) => hiddenPrint && PrintHidden};
`;

export const LayoutLoadingContainer = styled.div`
  padding-top: ${rem(spacing40)};
`;

export const LayoutCenteredContentStyled = styled.div`
  ${baseLayoutBounds};
  max-width: ${centeredContainerWidth};
  margin: auto;
  padding: ${rem(spacing32)} ${rem(spacing16)};
  ${mediaUp.lg`
    padding-left: ${rem(spacing16)};
    padding-right: ${rem(spacing16)};
  `}
`;

type LayoutDrawerProps = {
  $isActive: boolean;
  $isSmall: boolean;
  noScrollOnContainer?: boolean;
};
export const LayoutDrawerStyled = styled.div<LayoutDrawerProps>`
  background-color: ${grey1};
  box-shadow: 0px -8px 15px rgba(57, 16, 133, 0.08);
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 102; // just above header

  ${({ noScrollOnContainer }) =>
    noScrollOnContainer
      ? `
    overflow: hidden;
  `
      : `
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  `}

  ${mediaUp.md<LayoutDrawerProps>`
    width: ${({ $isSmall: isSmall }) =>
      isSmall
        ? rem(LAYOUT_DRAWER_MAX_WIDTH_SMALL)
        : rem(LAYOUT_DRAWER_MAX_WIDTH)};
  `}

  // add extra 5% to hide the box-shadow
  transform: translate3d(105%, 0, 0);
  transition: all ${LAYOUT_TRANSITION_DURATION}ms
      cubic-bezier(0.65, 0.05, 0.36, 1) 100ms,
    visibility 0ms ease 300ms;

  ${({ $isActive }) =>
    $isActive &&
    `
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all ${LAYOUT_TRANSITION_DURATION}ms cubic-bezier(0.65, 0.05, 0.36, 1) 300ms, visibility 0ms ease 0ms;
  `}
`;

type LayoutDrawerUnderlayProps = {
  $isActive: boolean;
};
export const LayoutDrawerUnderlayStyled = styled.div<LayoutDrawerUnderlayProps>`
  background-color: rgba(0, 0, 0, 0.05);
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  transition: opacity 300ms ease, visibility 0ms ease 300ms;
  z-index: 102; // just above header

  ${({ $isActive }) =>
    $isActive &&
    `
      opacity: 1;
      visibility: visible;
      transition: opacity 300ms ease, visibility 0ms ease 0ms;
    `}
`;

export const LayoutNarrowCenteredContentStyled = styled.div`
  max-width: ${narrowContainerWidth};
  margin: auto;
  padding: ${rem(spacing32)} ${rem(spacing24)};
`;

export const LayoutMultiViewStyled = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const LayoutMultiViewTrackStyled = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
`;

type LayoutMultiViewPageProps = {
  isActive: boolean;
  isBehind?: boolean;
};
export const LayoutMultiViewPageStyled = styled.div<LayoutMultiViewPageProps>`
  background-color: ${grey1};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0.5;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  visibility: hidden;
  width: 100%;

  & > div {
    // prevent scrollbar jank in IE
    min-height: 102vh;
  }
  transform: translate3d(10%, 0, 0);
  transition: transform 400ms ease, visibility 0ms linear;

  ${({ isActive }) =>
    isActive &&
    `
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 400ms ease, opacity 400ms ease, visibility 0ms linear;
  `}

  ${({ isBehind }) =>
    isBehind &&
    `
    transform: translate3d(0, 0, 0);
  `}
`;

type LayoutPageCenteredProps = {
  isHeaderless?: boolean;
};
export const LayoutPageCenteredStyled = styled.div<LayoutPageCenteredProps>`
  display: flex;
  min-height: calc(
    100vh -
      (
        ${({ isHeaderless }) =>
          isHeaderless ? 0 : `${LAYOUT_HEADER_HEIGHT}px`}
      )
  );
`;

// used to target field in layouts
export const NavigationWrapper = styled.div`
  ${flexCentreAlignCss}

  background-color: ${grey1};
  padding: ${rem(spacing16)};
  min-height: ${rem(74)};

  ${Dropdown}, ${NavigationClassesToggle} {
    max-width: ${rem(360)};
    width: 100%;
  }
`;
