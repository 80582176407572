import { ApolloClient } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { ServerError } from 'apollo-link-http-common';
import { Location, NavigateFunction } from 'react-router-dom';

import {
  PUBLIC_SESSION_TAKEN,
  PUBLIC_STUDENT_SIGNIN,
} from 'global/public-routes.constants';
import {
  PRIVATE_CLASS_BASE,
  PRIVATE_SIGNIN_REFRESH_SESSION,
} from 'global/private-routes.constants';
import {
  setUserData,
  isSignedIn,
  getAlreadySignedInOnce,
  getUserData,
  getUserId,
} from 'approot/shared/api/auth/auth';
import {
  getStudentUserData,
  setStudentUserData,
} from 'approot/shared/api/auth/student-user-auth';
import { updateAnalyticsDataLayer } from 'approot/shared/analytics/data-layer';
import { GET_USER } from 'approot/shared/user/user-data.graphql';
import { signOut, studentSignOut } from 'approot/shared/signin/signin.utils';
import {
  clearStudentSession,
  getStudentToken,
  getStudentUserToken,
} from 'approot/student-class-to-do/student-class-to-do.utils';
import {
  isExpiredClassToDoTokenServerError,
  isStaleSessionServerError,
} from 'approot/errors/error.utils';
import {
  ERROR_MESSAGE_CLASS_ARCHIVED,
  ERROR_MESSAGE_REMOVED_FROM_CLASS,
  GRAPHQL_REQUEST_TRACE_ID,
} from 'global/constants';
import { openSignInModal } from 'approot/global-notifications/global-notification.utils';
import {
  EVENT_CATEGORY_AUTHENTICATION,
  EVENT_ACTION_SESSION_TAKEN,
  EVENT_TARGET_TYPE_TEACHER,
  EVENT_TARGET_TYPE_STUDENT,
} from 'approot/shared/data-tracker/data-events.constants';
import { pushDataTrackerEvent } from 'approot/shared/data-tracker/data-tracker-push';
import { GET_STUDENT } from 'approot/students/students.graphql';
import { GetStudentById } from 'approot/students/__generated__/GetStudentById';
import { globalClassesNotificationVar } from 'approot/global-notifications/classes/global-classes-notification.apollo';
import { logException } from 'approot/shared/debug';
import { getRegionalisedLink } from 'approot/shared/logic/get-regionalised-link';
import { generateTraceId } from 'approot/shared/api/auth/generate-trace-id';
import { isStudentAuthenticated } from 'approot/students/logic/is-student-authenticated';

const SESSION_TAKEN_STATUS_CODE = 419;
const SESSION_EXPIRED_STATUS_CODE = 401;

export function getClientStateDefaults() {
  const userData = getUserData();
  const studentUserData = getStudentUserData();

  return {
    isSignedIn: isSignedIn(),
    isAlreadySignedInOnce: getAlreadySignedInOnce(),
    isStudentSignedIn: !!getStudentUserToken() || !!getStudentToken(),
    user: userData && userData.user,
    student: studentUserData?.studentUser,
  };
}

export function fetchUserIfSessionIsActive(client: ApolloClient<any>) {
  const userData = getUserData();

  if (userData && userData.token) {
    updateAnalyticsDataLayer(userData.user);
    const token = userData.token;

    client
      .query({
        query: GET_USER,
        fetchPolicy: 'network-only',
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            [GRAPHQL_REQUEST_TRACE_ID]: generateTraceId(),
          },
        },
      })
      .then(res => {
        if (res?.data?.user) {
          setUserData(
            {
              token,
              user: res.data.user,
            },
            userData.remember
          );
        } else {
          logException({
            sourceFile: 'apollo.utils.tsx',
            message: `There was an issue querying for user data. Response status  was: ${res.networkStatus}`,
            statusCode: res.networkStatus,
          });
        }
      });
  }
}

export function fetchStudentUserIfSessionIsActive(client: ApolloClient<any>) {
  const studentUserData = getStudentUserData();

  if (studentUserData && !studentUserData.studentUser) {
    studentSignOut(studentUserData.classToDoId);
    return;
  }

  if (studentUserData && studentUserData.token) {
    const token = studentUserData.token;

    client
      .query<GetStudentById, null>({
        query: GET_STUDENT,
        fetchPolicy: 'network-only',
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            [GRAPHQL_REQUEST_TRACE_ID]: generateTraceId(),
          },
        },
      })
      .then(res => {
        if (res.data.getStudentById) {
          setStudentUserData({
            token,
            studentUser: res.data.getStudentById,
            classId: studentUserData.classId || undefined,
            classToDoId: studentUserData.classToDoId || undefined,
          });
        }
      })
      .catch(() => {});
  }
}

export function checkIfSessionIsTaken(
  error: ErrorResponse,
  location?: Location,
  navigate?: NavigateFunction
) {
  const networkError = error.networkError;
  if (!networkError) {
    return;
  }

  const statusCode = (networkError as ServerError).statusCode;
  switch (statusCode) {
    case SESSION_TAKEN_STATUS_CODE: {
      if (isSignedIn()) {
        navigate?.(PUBLIC_SESSION_TAKEN, { replace: true });
        pushDataTrackerEvent(
          EVENT_CATEGORY_AUTHENTICATION,
          EVENT_ACTION_SESSION_TAKEN,
          {
            target_type: EVENT_TARGET_TYPE_TEACHER,
            target_identifier: getUserId(),
          }
        );
        // try and avoid apollo error: Store reset while query was in flight (not completed in link chain)
        setTimeout(() => signOut());
      } else if (isStudentAuthenticated()) {
        const studentUser = getStudentUserData();
        navigate?.(PUBLIC_SESSION_TAKEN, { replace: true });
        pushDataTrackerEvent(
          EVENT_CATEGORY_AUTHENTICATION,
          EVENT_ACTION_SESSION_TAKEN,
          {
            target_type: EVENT_TARGET_TYPE_STUDENT,
            target_identifier: studentUser?.studentUser.id,
          }
        );
        // try and avoid apollo error: Store reset while query was in flight (not completed in link chain)
        setTimeout(() => studentSignOut(studentUser?.classToDoId));
      }
      break;
    }
    case SESSION_EXPIRED_STATUS_CODE: {
      // student session error handling is dealt with separately so when there is a 401
      // we ignore it on paths starting with /class
      const path = location?.pathname;
      if (
        path &&
        !path.startsWith(`${PRIVATE_CLASS_BASE}/`) &&
        !isExpiredClassToDoTokenServerError(networkError as ServerError)
      ) {
        signOut();
        openSignInModal();
      }
      break;
    }
    default: {
      // pass
    }
  }
}

export function checkForCurrentClassError(error: ErrorResponse) {
  const networkError = error.networkError;
  if (!networkError) {
    return;
  }

  const classError = (networkError as any)?.result?.errors?.find(
    (e: any) =>
      e.message === ERROR_MESSAGE_CLASS_ARCHIVED ||
      e.message === ERROR_MESSAGE_REMOVED_FROM_CLASS
  );

  if (classError) {
    globalClassesNotificationVar(classError.message);
  }
}

export function checkForInvalidClassToDoToken(error: ErrorResponse) {
  const networkError = error.networkError;
  if (!networkError) {
    return;
  }

  const path = window.location.pathname;

  if (
    path.includes(`${PRIVATE_CLASS_BASE}/`) &&
    isExpiredClassToDoTokenServerError(networkError as ServerError)
  ) {
    clearStudentSession();
    window.location.replace(getRegionalisedLink(PUBLIC_STUDENT_SIGNIN));
  }
}

export function checkIfSessionIsStale(error: ErrorResponse) {
  const networkError = error.networkError;
  if (!networkError) {
    return;
  }

  if (isStaleSessionServerError(networkError as ServerError | undefined)) {
    window.location.replace(
      `${getRegionalisedLink(PRIVATE_SIGNIN_REFRESH_SESSION)}?redirect=${
        window.location.pathname
      }${window.location.search}`
    );
  }
}
