import {
  HTTP_GET,
  SERVERLESS_ENDPOINTS,
  HTTP_POST,
  AnyObject,
} from 'webclient.constants';
import { logException } from 'approot/shared/debug';
import { BaseResponse } from 'contracts/response';
import { IGNORED_STATUS_CODES } from 'global/constants';

const HTTP_STATUS_CODE_ERROR = 500;
const JSON_MIME_TYPE = 'application/json';

export const isResponseHeadOk = (response: any): boolean => {
  return !!(
    response &&
    response.ok &&
    response.status &&
    typeof response.status == 'number' &&
    response.status >= 200 &&
    response.status < 300
  );
};

export const isResponseBodyOk = (response?: BaseResponse): boolean =>
  !!(
    response &&
    response.statusCode &&
    typeof response.statusCode == 'number' &&
    response.statusCode >= 200 &&
    response.statusCode < 300
  );

export const getErrorMessage = (
  response?: BaseResponse,
  additionalMessage?: string
): string => {
  if (response) {
    return `Server endpoint ${
      response.statusCode
        ? `returned a status code of ${response.statusCode}`
        : 'errored'
    }.${response.error ? ` Error: ${response.error}.` : ''}${
      response.message ? ` Message: ${response.message}.` : ''
    }${additionalMessage ? ` ${additionalMessage}` : ''}`;
  } else {
    return `Server endpoint returned an unexpected response.${
      additionalMessage ? ` ${additionalMessage}` : ''
    }`;
  }
};

const getServerlessEndpoint = (origin: string, method: string): string => {
  const serverlessEndpoint = SERVERLESS_ENDPOINTS.find(
    endpoint => endpoint.origin === origin && endpoint.method === method
  );

  if (process.env.REACT_APP_SERVERLESS_ENDPOINT && serverlessEndpoint)
    return serverlessEndpoint.endpoint;
  return origin;
};

export const generateUrlParams = (params: AnyObject) =>
  Object.keys(params)
    .sort() // ensure stable key order for reliable tests
    .filter(param => params[param] !== undefined)
    .map(
      param =>
        `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`
    )
    .join('&');

export const generateHeaders = (headerFields: AnyObject) => {
  const headers = new Headers();
  Object.keys(headerFields)
    .sort()
    .forEach(key => {
      headers.append(key, headerFields[key]);
    });
  return headers;
};

export const generateFormBody = (postFields: AnyObject) => {
  const formData = new FormData();
  Object.keys(postFields).forEach(key => {
    formData.append(key, postFields[key]);
  });
  return formData;
};

export const generateJSONBody = (postJSON: AnyObject) =>
  JSON.stringify(postJSON);

type Init = {
  body?: any;
  headers?: Headers;
  method?: any;
};

type ServerlessRestArgs = {
  serverlessEndpoint?: {
    endpoint: string;
    resourceID: number | string;
  };
};

export type RequestArgs = [string, Init];

export type BuildPostArgs = {
  path: string;
  getFields?: AnyObject;
  postFields?: AnyObject;
  postJSON?: AnyObject;
  headerFields?: AnyObject;
};

export const buildPost = ({
  path,
  getFields,
  postFields,
  postJSON,
  headerFields,
}: BuildPostArgs): RequestArgs => {
  let body;
  let headersObj = { ...headerFields };
  if (postFields) {
    body = generateFormBody(postFields);
  } else if (postJSON) {
    body = generateJSONBody(postJSON);
    headersObj = {
      ...headersObj,
      'Content-Type': 'application/json',
    };
  }
  const paramString = getFields && generateUrlParams(getFields);
  const headers = Object.keys(headersObj).length
    ? generateHeaders(headersObj)
    : undefined;
  const serverlessPath = getServerlessEndpoint(path, HTTP_POST);
  const pathWithParams = `${serverlessPath}${
    paramString ? `?${paramString}` : ''
  }`;

  const init: Init = {
    method: 'POST',
    ...(headers ? { headers } : {}),
    ...(body ? { body } : {}),
  };

  return [pathWithParams, init];
};

export type BuildGetArgs = {
  path: string;
  getFields?: AnyObject;
  headerFields?: AnyObject;
  pathParam?: string;
};

export const buildGet = ({
  path,
  getFields,
  headerFields,
  pathParam,
}: BuildGetArgs): RequestArgs => {
  const paramString = getFields && generateUrlParams(getFields);
  const headers = headerFields && generateHeaders(headerFields);
  // Make sure we are comparing the path without params against servreless paths
  const paths = path.split('?');
  const cleanPath = paths[0];
  const serverlessPath = getServerlessEndpoint(cleanPath, HTTP_GET);
  const pathWithParams = `${serverlessPath}${pathParam ? `/${pathParam}` : ''}${
    paramString ? `?${paramString}` : paths[1] ? `?${paths[1]}` : ''
  }`;
  if (
    cleanPath !== path &&
    getFields &&
    process.env.NODE_ENV !== 'production'
  ) {
    console.warn(
      'Get Request: Setting params both in the path and `getFields` is not supported, request will be malformed:',
      { path, getFields },
      pathWithParams
    );
  }
  const init: Init = {
    method: HTTP_GET,
    ...(headers ? { headers } : {}),
  };

  return [pathWithParams, init];
};

export type BuildPutArgs = {
  path: string;
  getFields?: AnyObject;
  bodyFields?: AnyObject;
  bodyJSON?: AnyObject;
  bodyBlob?: Blob;
  headerFields?: AnyObject;
} & ServerlessRestArgs;

export type BuildPatchArgs = BuildPutArgs;

const handleCatchResponse = (
  e: Error,
  sourceFile: string
): Promise<Response> => {
  // Server APIs can break due to HTTP errors or CORS failures
  // which would mean that we don't see HTTP errors, so we want
  // to coerce all errors into looking like HTTP errors.
  //
  // Modified as per this pattern https://stackoverflow.com/a/36597197

  logException({ sourceFile, message: `${e}`, e });
  const resp: AnyObject = {
    headers: { get: () => JSON_MIME_TYPE },
    statusCode: HTTP_STATUS_CODE_ERROR, // clobber and pretend as if it's an HTTP error
    message: e.toString(),
    exception: e,
  };
  // @ts-ignore // FIXME: reenable TS on following line
  return Promise.resolve({
    ...resp,
    json: () => Promise.resolve(resp),
  });
};

type handleJSONArgs = {
  resp: Response;
  sourceFile: string;
  args?: AnyObject;
};

export const handleJSON = ({
  resp,
  sourceFile,
  args,
}: handleJSONArgs): AnyObject | undefined => {
  const contentType = resp.headers.get('content-type');
  const isJson = contentType && contentType.indexOf('application/json') !== -1;
  if (isJson) {
    return resp
      .json()
      .catch(e => {
        handleCatchResponse(e, sourceFile);
      })
      .then(
        (json: AnyObject | any[]): AnyObject => {
          if (Array.isArray(json)) {
            const message =
              "APIs shouldn't respond with top-level arrays. Use {} not [] for top-level API responses. The reason for this is that the server needs to add 'meta' for user updates, and webclient needs to add some extra fields to the response which we can't do with arrays.";
            console.error(message);
            throw Error(message);
          }
          const statusCode =
            json.statusCode || // incase statusCode was changed by catch
            (resp && resp.status) ||
            HTTP_STATUS_CODE_ERROR;
          if (!json) return { statusCode };

          const response = {
            ...json,
            statusCode,
          };

          // Ignore status codes that are probably aren't an issue (e.g. wrong password)
          if (!IGNORED_STATUS_CODES.includes(statusCode)) {
            logException({
              sourceFile,
              message: getErrorMessage(
                response,
                args ? `Data: ${JSON.stringify(args)}.` : ''
              ),
              statusCode,
            });
          }

          return response;
        }
      );
  }

  // eslint-disable-next-line no-console
  console.error(
    `Expected JSON but received content type ${
      contentType ? `"${contentType}"` : '(nothing)'
    }`,
    resp
  );

  return {
    statusCode: HTTP_STATUS_CODE_ERROR,
    message: `Unknown server response: ${
      contentType ? contentType : 'unknown'
    }`,
  };
};

type performFetchArgs = {
  args: RequestArgs;
  sourceFile: string;
};
export const performFetch = ({
  args,
  sourceFile,
}: performFetchArgs): Promise<Response> => {
  return fetch(args[0], args[1]).catch(e => handleCatchResponse(e, sourceFile));
};

export const get = (
  { path, getFields, headerFields }: AnyObject,
  sourceFile: string
) =>
  performFetch({
    args: buildGet({ path, getFields, headerFields }),
    sourceFile,
  });

export const post = (
  { path, postFields, headerFields }: AnyObject,
  sourceFile: string
) => {
  const postArgs = buildPost({ path, postFields, headerFields });
  return performFetch({ args: postArgs, sourceFile });
};
