import { ReactNode } from 'react';
import { ApolloError, ServerError as ApolloServerError } from '@apollo/client';

import {
  isFailedToFetch,
  isNotFound,
  isUnauthorised,
  isAlreadyLoggedIn,
  isStaleSessionServerError,
} from './error.utils';
import NotFound from './not-found';
import ServerError from './server-error';
import FailedToFetch from './failed-to-fetch';
import UnauthorisedError from './unauthorised-error';
import { ErrorPageStyled } from './errors.style';
import AlreadyLoggedIn from './already-logged-in';

type Props = {
  error?: ApolloError;
  isHeaderless?: boolean;
  children?: ReactNode;
};

const Error = ({ error, isHeaderless = false, children }: Props) => {
  if (children) {
    return (
      <ErrorPageStyled isHeaderless={isHeaderless}>{children}</ErrorPageStyled>
    );
  }

  if (
    isStaleSessionServerError(
      error?.networkError as ApolloServerError | undefined
    )
  ) {
    // a redirect will handle this type of error
    return null;
  }

  let component = null;
  if (isFailedToFetch(error)) {
    component = <FailedToFetch />;
  } else if (isNotFound(error)) {
    component = <NotFound />;
  } else if (isUnauthorised(error)) {
    component = <UnauthorisedError />;
  } else if (isAlreadyLoggedIn(error)) {
    component = <AlreadyLoggedIn />;
  } else {
    component = <ServerError />;
  }
  return (
    <ErrorPageStyled isHeaderless={isHeaderless}>{component}</ErrorPageStyled>
  );
};

export default Error;
