import { AccountType } from 'global/constants';

export const INTERCOM_LAUNCH_TARGET_CLASS = 'intercom-target';

type Intercom = any;
declare var window: Intercom;

type IntercomUserData = {
  id: number;
  email: string;
  hash: string;
  schoolId: number;
  schoolName: string;
  accountType: AccountType;
};

export function endIntercomSession() {
  if (!window.Intercom) return;
  window.Intercom('shutdown');
}

export function startAuthenticatedIntercomSession(user: IntercomUserData) {
  if (!window.Intercom) return;
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_WORKSPACE,
    email: user.email,
    user_id: user.id,
    user_hash: user.hash,
    created_at: Date.now(),
    account_type: user.accountType,
    company: {
      id: user.schoolId,
      name: user.schoolName,
      created_at: Date.now(),
    },
  });
}

export function startUnauthenticatedIntercomSession() {
  if (!window.Intercom) return;
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_WORKSPACE,
    created_at: Date.now(),
  });
}

export function updateIntercomOnUrlChange(user: IntercomUserData | undefined) {
  if (!window.Intercom) return;
  window.Intercom('update', {
    app_id: process.env.REACT_APP_INTERCOM_WORKSPACE,
    last_request_at: Number(new Date().getTime() / 1000),
    email: user?.email,
    user_id: user?.id,
    user_hash: user?.hash,
    account_type: user?.accountType,
    company: user
      ? {
          id: user.schoolId,
          name: user.schoolName,
          created_at: Date.now(),
        }
      : undefined,
  });
}
