import { QueryHookOptions } from '@apollo/client';
import { logException } from 'approot/shared/debug';
import { localStorageWrapper } from 'approot/shared/storage';
import { AnyObject } from 'webclient.constants';
import { GetStudentLesson_getLessonById } from 'approot/student-lesson/__generated__/GetStudentLesson';
import { GetStudentAssessment_getAssessmentById } from 'approot/student-assessment/__generated__/GetStudentAssessment';
import { getStudentUserData } from 'approot/shared/api/auth/student-user-auth';

const STUDENT_DATA_KEY = `studentData:${process.env.REACT_APP_REGION}`;

type StudentData = {
  token?: string;
  classId?: string;
  teacher?: AnyObject;
  teacherClassId?: number;
};

export const getStudentData = (): StudentData => {
  const dataString = localStorageWrapper.getItem(STUDENT_DATA_KEY);
  if (dataString) {
    try {
      return JSON.parse(dataString);
    } catch (e) {
      logException({
        sourceFile: 'student-class-to-do.utils.tsx',
        message: `Invalid student data: ${dataString}`,
        e,
      });
    }
  }
  return {};
};

export const getStudentToken = () => getStudentData().token;
export const getStudentTeacher = () => getStudentData().teacher;
export const getStudentClassId = () => getStudentData().classId;
export const getStudentUserToken = () => getStudentUserData()?.token;

export const isStudentSignedIn = () => !!getStudentToken();

export const setStudentData = (studentData: StudentData) => {
  if (!studentData) return;

  const dataString = JSON.stringify(studentData);
  try {
    localStorageWrapper.setItem(STUDENT_DATA_KEY, dataString);
  } catch (e) {
    logException({
      e,
      sourceFile: 'student-class-to-do.utils.tsx',
      message: `Write student data error: ${dataString}`,
    });
  }
};

export const clearStudentSession = () => {
  localStorageWrapper.removeItem(STUDENT_DATA_KEY);
};

export function getClassToDoOrStudentToken() {
  let token;

  const studentUserToken = getStudentUserToken();
  if (studentUserToken) {
    token = studentUserToken;
  } else {
    token = getStudentToken();
  }

  return token;
}

export function getClassToDoOrStudentClassToDoId() {
  let classToDoId;
  const studentUser = getStudentUserData();
  if (!studentUser) {
    classToDoId = getStudentClassId();
  } else {
    classToDoId = studentUser.classToDoId;
  }

  return classToDoId;
}

export const studentAuthApolloContextOption = (): QueryHookOptions['context'] => {
  return {
    headers: {
      authorization: `Bearer ${getClassToDoOrStudentToken()}`,
    },
  };
};

export function getContentUnit(
  contentItem:
    | GetStudentLesson_getLessonById
    | GetStudentAssessment_getAssessmentById
    | null
    | undefined,
  params: { parentId: string } | undefined
) {
  const unitId: number | undefined = params?.parentId
    ? parseInt(params.parentId)
    : contentItem?.unit.id;
  return contentItem?.unit.id === unitId
    ? contentItem?.unit
    : contentItem?.integratedUnit;
}
